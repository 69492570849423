<template>
  <a-dialog-form :value="value" @input="close()">
    <v-card>
      <v-card-title>Послать в мессенджер</v-card-title>
      <v-card-text>
        <div>
          <span v-for="el,i in templateModel" :key="i">
            <v-btn @click="message = el.text">{{el.name}}</v-btn>
          </span>
        </div>
        <div>
          <v-text-field v-model="sendPhone" label="Номер"/>
          <v-textarea v-model="intro" label="Приветствие" />
          <v-textarea v-model="message" label="Сообщение" />
        </div>
      </v-card-text>
      <v-card-actions>
        <a-btn-form-close @click="close()" />
        <a-btn-form-save @click="submit()">Послать</a-btn-form-save>
      </v-card-actions>
    </v-card>
  </a-dialog-form>
</template>

<script>
export default {
  props: {
    value: Boolean,
    phone: String,
    name: String,
    item_id: Number,
  },
  data() {
    return {
      sendPhone: "",
      message: "",
      intro: "",
      template: 1,
      templateModel: this.$root.config.meta.const.templatesTele
    };
  },
  created() {
    this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  computed: {},
  methods: {
    init() {
      this.sendPhone = this.phone;
      let name = this.name ? this.name : "";
      this.intro = `Здравствуйте ${name}, это Мадина из AVinfoBot `
    },
    close() {
      this.$emit("input");
    },
    async submit() {
      try {
        await this.$axios.p(this.$root.api.sendWhatsappbot, {
          phone: this.sendPhone,
          intro: this.intro,
          name: this.name ? this.name : this.sendPhone,
          message: this.message,
        });
        this.$root.$emit("show-info", {
          text: "Послали сообщение на номер " + this.sendPhone,
        });
        this.close();
      } catch (error) {
        this.$root.$emit("show-info", {
          text: "Ошибка " + error,
        });
      }
    },
  },
};
</script>